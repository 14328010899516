import React, { useState } from "react";
import "./navbar.css";
import { NavLink, useLocation } from "react-router-dom";
import StringData from "../String.json";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const location = useLocation();

  const getActiveNavItem = (path) => {
    // Function to determine active nav item
    switch (path) {
      case "/":
        return "home";
      case "/about":
        return "about";
      case "/services":
        return "services";
      case "/portfolio":
        return "portfolio";
      case "/blog":
        return "blog";
      default:
        return "";
    }
  };

  const activeNavItem = getActiveNavItem(location.pathname);

  return (
    <>
      <div className="container-fluid navbar">
        <div className="container ">
          <div className="logo-section">
            <NavLink to="/">
              <img
                src={StringData.site.logo}
                alt="seeds-logo"
                className={activeNavItem === "home" ? "active" : ""}
              />
            </NavLink>
          </div>
          <div className="navbar-links">
            <li>
              <NavLink to="/">
                <p className={activeNavItem === "home" ? "active" : ""}>Home</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/about">
                <p className={activeNavItem === "about" ? "active" : ""}>
                  About
                </p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/services">
                <p className={activeNavItem === "services" ? "active" : ""}>
                  Services
                </p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/portfolio">
                <p className={activeNavItem === "portfolio" ? "active" : ""}>
                  Portfolio
                </p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/blog">
                <p className={activeNavItem === "blog" ? "active" : ""}>Blog</p>
              </NavLink>
            </li>
          </div>
          <div className="navbar-mob">
            {!navOpen ? (
              <div className="nav-close" onClick={() => setNavOpen(true)}>
                <MenuIcon style={{ fontSize: "2.5rem" }} />
              </div>
            ) : (
              <div className="nav-open" onClick={() => setNavOpen(false)}>
                <div className="close-icon">
                  <CloseIcon style={{ fontSize: "2.5rem" }} />
                </div>

                <div className="navbar-items open">
                  <li>
                    <NavLink to="/">
                      <p className={activeNavItem === "home" ? "active" : ""}>
                        Home
                      </p>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/about">
                      <p className={activeNavItem === "about" ? "active" : ""}>
                        About
                      </p>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/services">
                      <p
                        className={activeNavItem === "services" ? "active" : ""}
                      >
                        Services
                      </p>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/portfolio">
                      <p
                        className={
                          activeNavItem === "portfolio" ? "active" : ""
                        }
                      >
                        Portfolio
                      </p>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/blog">
                      <p className={activeNavItem === "blog" ? "active" : ""}>
                        Blog
                      </p>
                    </NavLink>
                  </li>
                  <div className="social-links">
                    <a href="https://www.facebook.com/profile.php?id=61554031543717">
                      <FacebookIcon />
                    </a>
                    <a href="https://www.instagram.com/">
                      <InstagramIcon />
                    </a>
                    <a href="https://www.youtube.com/">
                      <TwitterIcon />
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
