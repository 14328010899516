import React from "react";
import ServiceSection from "../../Home/ServiceSection/ServiceSection";
import GoToTop from "../../GoToTop/GoToTop";

const Servicepage = () => {
  return (
    <>
      <div className="servcie-page">
        <ServiceSection />
      </div>
      <GoToTop/>
    </>
  );
};

export default Servicepage;
